@import "~@angular/cdk/overlay-prebuilt.css";
@import 'assets/css/overrides-openlayers.css';
@import 'assets/css/overrides-devexpress.css';

html,body {
  margin: 0px;
  width: 100%;
  height: 100%;
  padding: 0px;
}

/* Tamaño del scroll */
::-webkit-scrollbar {
  width: 6px;
}

 /* Estilos barra (thumb) de scroll */
::-webkit-scrollbar-thumb {
  background: #b6c5ce;
  cursor: pointer;
  border-radius: .15625rem;
}

::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
}

 /* Estilos track de scroll */
::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.vh-100 {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #b6c5ce #d4d4d4;
}

.form-control {
  padding: 0.3rem 0.5rem !important;
}


.h-45 {
  height: 45% !important;
}

.h-55 {
  height: 45% !important;
}

.h-30 {
  height: 30% !important;
}

.h-70 {
  height: 70% !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}